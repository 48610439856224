<template>
  <v-card>
    <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        <v-toolbar-title>
          {{ $t("NORMALIZE_DATA.LIST") }}
        </v-toolbar-title>
      </v-tab>
      <v-spacer></v-spacer>

      <div class="buttons-tab">
        <v-btn
          color="mr-4 orange"
          v-on:click="createNormalizeData()"
          :loading="isProcessing"
          v-if="hasPermissions('normalize-data-create')"
          >Add Normalize Data</v-btn
        >
      </div>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <v-card-text>
            <Table
              :path="path"
              tableId="normalize_data"
              dbTable="normalize_data"
              :tableInit="tableInit"
              :showParams="showParams"
              :filtersFields="filtersFields"
            ></Table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { TABLE_INIT_URL, GET_NORMALIZE_DATA_INIT } from "@/store/normalize_data.module";

import Table from "@/views/partials/table/Table.vue";

export default {
  components: {
    Table,
  },
  name: "ShowNormalizeData",
  data() {
    return {
        isProcessing: false,
        path: TABLE_INIT_URL,
        tableInit: GET_NORMALIZE_DATA_INIT,
        tab: "tab-1",
        showParams: { name: "edit-normalize-data", key: "id" },
        filtersFields: [],
        file:null,
        url:null
    };
  },
  methods:{
    createNormalizeData() {
     this.$router.push({ name: "create-normalize-data"});
    }
  },
};
</script>

<style>
</style>